// Generated by Framer (44d7d2d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sHTW23AUu", "VTW41htkR"];

const serializationHash = "framer-yVxP6"

const variantClassNames = {sHTW23AUu: "framer-v-44m4y0", VTW41htkR: "framer-v-1mwe12x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "sHTW23AUu", "Variant 2": "VTW41htkR"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sHTW23AUu"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sHTW23AUu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1agkoby = activeVariantCallback(async (...args) => {
await delay(() => setVariant("VTW41htkR"), 300)
})

const onAppear16gsh33 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("sHTW23AUu"), 300)
})

useOnVariantChange(baseVariant, {default: onAppear1agkoby, VTW41htkR: onAppear16gsh33})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", pixelHeight: 360, pixelWidth: 500, src: "https://framerusercontent.com/images/pw0T7yyqMBFl2smwj6WgOfCKHk.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-44m4y0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"sHTW23AUu"} ref={ref ?? ref1} style={{rotate: 0, ...style}} variants={{VTW41htkR: {rotate: 291}}} {...addPropertyOverrides({VTW41htkR: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yVxP6.framer-172kvpn, .framer-yVxP6 .framer-172kvpn { display: block; }", ".framer-yVxP6.framer-44m4y0 { height: 223px; position: relative; width: 294px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 223
 * @framerIntrinsicWidth 294
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VTW41htkR":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW16tPYeCw: React.ComponentType<Props> = withCSS(Component, css, "framer-yVxP6") as typeof Component;
export default FramerW16tPYeCw;

FramerW16tPYeCw.displayName = "Bell";

FramerW16tPYeCw.defaultProps = {height: 223, width: 294};

addPropertyControls(FramerW16tPYeCw, {variant: {options: ["sHTW23AUu", "VTW41htkR"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerW16tPYeCw, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})